.GreenToggle.react-toggle {
    
	position: absolute;
    z-index: 10;
    right: 6px;
    top: 0.01vh;

	.react-toggle-thumb {
		border: none;
	}

	&.react-toggle .react-toggle-thumb,
	&.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
		box-shadow: 0 0 2px 2px #f7f7f7;;
	}

	.react-toggle-track,
	&.react-toggle--checked .react-toggle-track,
	&.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track,
	&.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
		background: rgb(32, 35, 42);;
	}


	.react-toggle-track-check {
		left: 1vw;
	}

	.react-toggle-track-x {
		right: 1vw;
	}

	.react-toggle-track-check,
	.react-toggle-track-x {
		font-size: 12px;
		line-height: 2em;
		height: 100%;
		text-shadow: 0 0 #f7f7f7;;
		color: transparent;
	}
}