@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");

$fonts-1: "Poppins", sans-serif;
$color-1: #61dafb;
$color-2: #f7f7f7;
$color-3: #f7f7f7;
$color-4: rgb(32, 35, 42);

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    --color-1: #{$color-1};
    --color-2: #{$color-2};
    --color-3: #{$color-3};
    --color-4: #{$color-4};
    margin: 0;
    font-family: $fonts-1;
    background-color: var(--color-2);
    width:100vh;
    overflow: hidden;
  }


.active{
  color:red;
  transform: scale(1.5);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

}


li{
  list-style-type: none;
}
a{
  text-decoration: none;
  color: var(--color-3);
  cursor: pointer;
}
a:visited{
  color: var(--color-3);
}